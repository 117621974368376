import { makeStyles } from 'tss-react/mui';

import type { Theme } from '@mui/material/styles';
import type { CSSObject } from 'tss-react';

export const useHomeHeroStyles = makeStyles()(({ breakpoints, typography, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.background.default,
    color: palette.primary.contrastText,
    paddingLeft: 0,
    paddingRight: 0,
    flexGrow: 1,

    overflow: 'hidden',
    minHeight: 820,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [breakpoints.up('sm')]: {
      backgroundImage:
        'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAyNSIgaGVpZ2h0PSI1ODQiIHZpZXdCb3g9IjAgMCAxMDI1IDU4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNTgzLjExMUwtNi42NjU1NmUtMDYgMjQuNjkzN0wtNi45NjAzMmUtMDYgMS4yMjIwNmUtMDVMMTAyNSAwQzk5OC4wMSA5My4yNjYgOTU4LjU3MiAxODEuODk2IDkwNy44NTkgMjQyLjg4NUM4MDYuMDE4IDM2NS4zNjIgNjY0Ljk0MiA0NDkuMDU4IDUwOC40MjcgNDc5Ljg1NEwwIDU4My4xMTFaIiBmaWxsPSIjMjgyMTMyIi8+Cjwvc3ZnPgo=")',
    },

    [breakpoints.down('md')]: {
      backgroundImage:
        'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzc1IiBoZWlnaHQ9IjU0MiIgdmlld0JveD0iMCAwIDM3NSA1NDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zNzUgNTQyTDM3NSAyOS43MDkyTDM3NSAtMS42Mzg4M2UtMDVMLTIuMzY5MTZlLTA1IDBMNi4yNDU4NWUtMDUgMjI0LjM4M0MwLjQ5MDIyNSAyODkuNTQzIDIwLjI5MzggMzUyLjUxMiA1Ni4wNzAzIDQwMi42NjlDOTEuODQ2OCA0NTIuODI3IDE0MS40MDcgNDg3LjEwMiAxOTYuMzkgNDk5LjcxNEwzNzUgNTQyWiIgZmlsbD0iIzI4MjEzMiIvPgo8L3N2Zz4K")',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    flexGrow: 1,
    [breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  leftBox: {
    [breakpoints.down('md')]: {
      marginTop: 44,
      marginBottom: 32,
    },
  },
  link: {
    color: palette.accent.main,
    display: 'inline',
  },
  heading: {
    color: palette.primary.contrastText,
    [breakpoints.down('md')]: {
      ...(typography.h3 as CSSObject),
      lineHeight: '40px',
      fontFamily: "'Articulat CF', sans-serif",
    },
    fontSize: 40,
    lineHeight: '52px',
    fontWeight: 700,
    fontFamily: "'Articulat CF', sans-serif",
  },
  subheading: {
    marginBottom: 32,
    marginTop: 20,
    [breakpoints.down('md')]: {
      marginBottom: 20,
      marginTop: 12,
    },
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    fontFamily: "'Articulat CF', sans-serif",
  },
  video: {
    width: '25%',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  hiddenXsDown: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hiddenSmUp: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const useButtonWrapper = makeStyles()(({ breakpoints, spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& > *': {
      marginLeft: spacing(0.5),
    },
  },
}));
