import * as homeHeroStyles from './HomeHero.styles';
import HeroReviews from './components/HeroReviews';

import Button from '../Button';
import ProceedButton from '../ProceedButton';
import { PublicRoutePath } from '../../utils/routes';
import useLinkTracker from '../../utils/tracking/useLinkTracker';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from 'next/link';
import Typography from '@mui/material/Typography';

function HomeHero() {
  const { classes: heroClasses } = homeHeroStyles.useHomeHeroStyles();

  const linkRef = useLinkTracker('HOMEPAGE_CHECK_AVAILABILITY');
  const quoteLinkRef = useLinkTracker('HOMEPAGE_QUOTE_BTN');

  return (
    <div className={heroClasses.root}>
      <Container className={heroClasses.container}>
        <Box className={heroClasses.leftBox} whiteSpace="pre-wrap" mb={1}>
          <Typography component="h1" variant="h2" className={heroClasses.heading}>
            {`The easy way to order concrete online `}
          </Typography>
          <Typography component="h4" variant="h4" className={heroClasses.subheading}>
            Join thousands of tradies booking concrete with Found.
          </Typography>
          <Grid container spacing={3}>
            <Grid item>
              <Button ref={linkRef} href="/check-availability" size="large">
                Check availability
              </Button>
            </Grid>
            <Grid item>
              <Link passHref href={PublicRoutePath.QUOTE_PROCESS}>
                <ProceedButton ref={quoteLinkRef} component="a" size="large">
                  Get a quote
                </ProceedButton>
              </Link>
            </Grid>
          </Grid>
          <HeroReviews />
        </Box>
        <video autoPlay loop muted className={heroClasses.video}>
          <source src="/static/phone-video.webm" type="video/webm" />
        </video>
      </Container>
    </div>
  );
}

export default HomeHero;
