import * as HeroReviewsStyles from './HeroReviews.styles';

import environment from '../../../../utils/environment';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Head from 'next/head';
import Script from 'next/script';
import { memo, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

const REVIEWS_IO_WIDGET_ID = 'review-io-text-banner-widget';

function renderWidget(accentMain: string, commonWhite: string) {
  if (environment.isClient) {
    // @ts-expect-error richSnippetReviewsWidgets needs to be added as a type
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.richSnippetReviewsWidgets(REVIEWS_IO_WIDGET_ID, {
      store: 'found-concrete',
      starsClr: accentMain,
      textClr: commonWhite,
      logoClr: 'white',
      widgetName: 'text-banner',
    });
  }
}

function HeroReviews() {
  const { classes } = HeroReviewsStyles.useWrapper();
  const { palette } = useTheme();

  const containerRef = useRef(null);

  // @ts-expect-error richSnippetReviewsWidgets needs to be added as a type
  const scriptPresent = environment.isClient && !!window.richSnippetReviewsWidgets;

  const [scriptLoaded, setScriptLoaded] = useState(scriptPresent);

  const accentMain = palette.accent.main;
  const commonWhite = palette.common.white;

  // Will only render widget if we're on the client, the dom element is present and the script is loaded
  useEffect(() => {
    if (containerRef.current && scriptLoaded) renderWidget(accentMain, commonWhite);
  }, [accentMain, commonWhite, containerRef, scriptLoaded]);

  let originName = '';
  if (typeof window !== 'undefined') {
    originName = window.location.origin;
  }
  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2022032403" />
      </Head>
      {originName.indexOf('foundconcrete.com.au') > -1 && (
        <Script
          onLoad={() => setScriptLoaded(true)}
          src="https://widget.reviews.io/rich-snippet-reviews-widgets/dist.js"
        />
      )}

      <div ref={containerRef} id={REVIEWS_IO_WIDGET_ID} className={classes.root}>
        <CircularProgress
          sx={{ position: 'absolute', margin: 'auto', top: 0, bottom: 0, right: 0, left: 0 }}
          color="accent"
        />
      </div>
    </>
  );
}

// Use memo to prevent re-renders which would wipe loaded widget
export default memo(HeroReviews);
