import Box from '@mui/material/Box';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import { StaticImageData } from 'next/image';

import Image from '../../../Image';
import useContentRowStyles from './ContentRow.styles';

type ContentRowProps = {
  buttonText: string;
  imgSrc: StaticImageData;
  reverse?: boolean;
  subtitle: string;
  title: string;
  to: string;
};

function ContentRow({ buttonText, imgSrc, reverse = false, subtitle, title, to }: ContentRowProps) {
  const { classes } = useContentRowStyles();

  return (
    <Box className={classes.root} flexDirection={reverse ? 'row-reverse' : 'row'}>
      <Box className={classes.box}>
        <Image unoptimized alt={title} src={imgSrc} />
      </Box>
      <Box className={classes.box}>
        <Typography component="h3" variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography component="span" variant="h4" color="textPrimary">
          {subtitle}
        </Typography>
        <Box padding="12px 0">
          {!!to && (
            <Link href={to}>
              <a className={classes.link}>{buttonText}</a>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ContentRow;
