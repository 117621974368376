import type { ReactElement } from 'react';

import HomeComponent from '../src/components/Home';
import HomeLayout from '../src/components/HomeLayout';
import { NextPageWithLayout } from './_app';

const Page: NextPageWithLayout = () => <HomeComponent />;

Page.getLayout = function getLayout(page: ReactElement) {
  return <HomeLayout>{page}</HomeLayout>;
};

export default Page;
