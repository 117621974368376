import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useWrapper = makeStyles()(({ breakpoints, palette }: Theme) => ({
  reviews: {
    paddingBottom: 88,
    paddingTop: 88,
    backgroundColor: palette.primary.dark,
    [breakpoints.down('md')]: {
      paddingBottom: 80,
      paddingTop: 56,
    },
  },
  reviewsTitle: {
    color: palette.primary.contrastText,
    fontFamily: '"Articulat CF", sans-serif',
    [breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  buttonBox: {
    textAlign: 'center',
    [breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
}));
