import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as layoutStyles from './HomeLayout.styles';
import Footer from '../Footer';
import TopToolbar from '../TopToolbar';
import { AppContextProvider } from '../../context/app/AppContext';
import { PublicRoutePath } from '../../utils/routes';
import { authenticated$ } from '../../store/session/selectors';
import { deletePerform as logout } from '../../store/session/actions';

function HomeLayout({ children }: PropsWithChildren<unknown>) {
  const { classes: allLayoutClasses } = layoutStyles.useLayoutStyles();
  const isAuthenticated = useSelector(authenticated$);

  const dispatch = useDispatch();

  const logoutCallback = () => {
    window.setTimeout(() => window.location.assign(PublicRoutePath.HOME), 100);
  };

  const handleLogout = () => {
    if (window.confirm('Are you sure?')) dispatch(logout({ callback: logoutCallback }));
  };

  return (
    <AppContextProvider>
      <div className={allLayoutClasses.root}>
        <TopToolbar dark={false} isAuthenticated={isAuthenticated} onLogout={handleLogout} />
        <main className={allLayoutClasses.main}>
          <div className={`${allLayoutClasses.toolbar} ${allLayoutClasses.center}`} />
          <div className={allLayoutClasses.content}>{children}</div>
          <Footer />
        </main>
      </div>
    </AppContextProvider>
  );
}

export default HomeLayout;
