import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints, palette }: Theme) => ({
  root: {
    fontFamily: '"Articulat CF", sans-serif',
  },
  container: {
    [breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  howItWorks: {
    backgroundColor: palette.secondary.lighter,
    textAlign: 'center',
    marginTop: 112,
    paddingBottom: 88,
    paddingTop: 88,
    [breakpoints.down('md')]: {
      paddingBottom: 80,
      paddingTop: 56,
    },
  },
  howItWorksTitle: {
    textAlign: 'center',
    fontFamily: '"Articulat CF", sans-serif',
    [breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  howItWorksSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 88,
    flexDirection: 'row',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingBottom: 36,
    },
  },
  reviews: {
    paddingBottom: 88,
    paddingTop: 88,
    backgroundColor: palette.primary.dark,
    [breakpoints.down('md')]: {
      paddingBottom: 80,
      paddingTop: 56,
    },
  },
  reviewsTitle: {
    color: palette.primary.contrastText,
    fontFamily: '"Articulat CF", sans-serif',
    [breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  reviewsBody: {
    marginTop: 48,
    marginBottom: 56,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  buttonBox: {
    textAlign: 'center',
    [breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  about: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 88,
    paddingTop: 88,
    textAlign: 'center',

    '& > h2': {
      marginBottom: 36,
    },
    '& > h3': {
      fontSize: '26px',
      marginBottom: 12,
    },
    '& > p': {
      fontSize: '20px',
      fontWeight: 400,
      marginTop: 32,
      [breakpoints.down('md')]: {
        marginTop: 8,
      },
    },
    '& > p, h2, h3, h4': {
      fontFamily: '"Articulat CF", sans-serif',
    },
    '& > h4': {
      color: palette.secondary.light,
    },

    [breakpoints.down('md')]: {
      paddingBottom: 80,
      paddingTop: 56,
      textAlign: 'left',
    },
  },
  aboutButton: {
    marginTop: 56,
    textAlign: 'center',
    [breakpoints.down('md')]: {
      marginTop: 48,
      textAlign: 'left',
    },
  },
}));
