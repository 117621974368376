import { makeStyles } from 'tss-react/mui';

import type { Theme } from '@mui/material/styles';

export default makeStyles()(({ breakpoints, palette, spacing }: Theme) => ({
  root: {
    marginTop: 64,
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      marginTop: 56,
      marginBottom: 44,
      flexDirection: 'row',
      textAlign: 'left',
    },
  },
  leftBox: {
    paddingLeft: 24,
    paddingRight: 24,
    [breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  rightBox: {
    paddingLeft: 24,
    paddingRight: 24,
    [breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  box: {
    color: palette.secondary.light,
    paddingTop: 8,
    paddingBottom: 8,
    '& > h4': {
      fontFamily: '"Articulat CF", sans-serif',
      marginBottom: 12,
      [breakpoints.down('md')]: {
        marginBottom: 8,
      },
    },
    '& > span': {
      fontFamily: '"Articulat CF", sans-serif',
    },
  },
  link: {
    color: palette.proceed.light,
    fontWeight: 'bold',
    fontSize: 20,
    cursor: 'pointer',
    lineHeight: spacing(5),
  },
}));
