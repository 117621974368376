import useHowItWorksBoxStyles from './HowItWorksBox.styles';

import Image from '../../../Image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from 'next/link';


type HowItWorksBoxProps = {
  imgSrc: string;
  subtitle: string;
  title: string;
  extraLinkText?: string;
  extraLinkHref?: string;
};

function HowItWorksBox({ imgSrc, subtitle, title, extraLinkText, extraLinkHref }: HowItWorksBoxProps) {
  const { classes } = useHowItWorksBoxStyles();

  return (
    <Box className={classes.root} display="flex">
      <Box className={`${classes.leftBox} ${classes.box}`}>
        <Image width={48} height={48} alt={title} src={imgSrc} />
      </Box>
      <Box className={`${classes.rightBox} ${classes.box}`}>
        <Typography component="h4" variant="h4">
          {title}
        </Typography>
        <Typography component="p" variant="h5">
          {subtitle}
        </Typography>
        {extraLinkText && extraLinkHref && (
          <Link href={extraLinkHref}>
            <Typography component="a" variant="button" className={classes.link}>
              {extraLinkText}
            </Typography>
          </Link>
        )}
      </Box>
    </Box>
  );
}

export default HowItWorksBox;
