import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints, palette }: Theme) => ({
  root: {
    display: 'flex',
    marginTop: 112,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      marginTop: 80,
      flexDirection: 'column-reverse',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: '50%',
    padding: '0 40px',
    [breakpoints.down('md')]: {
      flexBasis: 'auto',
      padding: '14px 0',
    },
    '& > h2, h3, h4, span': {
      fontFamily: '"Articulat CF", sans-serif',
    },
    '& > span': {
      color: palette.secondary.light,
    },
  },
  link: {
    color: palette.proceed.light,
    fontWeight: 'bold',
    fontSize: 20,
  },
}));
