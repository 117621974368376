import { PageHeadTitle } from '../../utils/routes';

import Head from 'next/head';

import type { ReactNode } from 'react';

export interface PageHeadProps {
  children?: ReactNode;
  /**
   * Title will be prefix to the web app name.
   * @example `New order - Founc Concrete`
   */
  title?: keyof typeof PageHeadTitle;
}

/**
 * Provides a head template with common head tags for pages
 * @returns A Next.js Head component wrapped around common tags
 * @remarks If you want to include a global script, consider putting it in _app.tsx first
 */
function PageHead({ children, title }: PageHeadProps) {
  const extendedTitle = title ? `${PageHeadTitle[title]} - Found Concrete` : 'Found Concrete';

  return (
    <Head>
      <title>{extendedTitle}</title>
      {children}
    </Head>
  );
}

export default PageHead;
