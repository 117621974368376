import ContentRow from './components/ContentRow';
import HowItWorksBox from './components/HowItWorksBox';
import Reviews from './components/Reviews';
import useHomeStyles from './Home.styles';

import HomeHero from '../HomeHero';
import ProceedButton from '../ProceedButton';
import step1Icon from '../../assets/images/step1.svg';
import step2Icon from '../../assets/images/step2.svg';
import step3Icon from '../../assets/images/step3.svg';
import step4Icon from '../../assets/images/step4.svg';
import vp1Image from '../../assets/images/vp1-image.webp';
import vp2Image from '../../assets/images/vp2-image.webp';
import vp3Image from '../../assets/images/vp3-image.webp';
import { PrivateRoutePath, PublicRoutePath } from '../../utils/routes';
import { authenticated$ } from '../../store/session/selectors';
import PageHead from '../PageHead';

import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import type { NextPage } from 'next';

const Home: NextPage = () => {
  const authenticated = useSelector(authenticated$);
  const { classes } = useHomeStyles();

  const { push } = useRouter();

  useEffect(() => {
    if (authenticated) {
      void push({
        pathname: PrivateRoutePath.ORDERS,
        query: { filter: 'upcoming' },
      });
    }
  }, [authenticated, push]);

  return (
    <div className={classes.root}>
      <PageHead />
      <HomeHero />
      <Container className={classes.container}>
        <ContentRow
          buttonText="Get started"
          imgSrc={vp1Image}
          subtitle="Choose your mix, delivery time and location in less than 2 minutes."
          title="Book anytime, anywhere"
          to={PublicRoutePath.QUOTE_PROCESS}
        />
        <ContentRow
          buttonText="Get a quote"
          imgSrc={vp2Image}
          reverse
          subtitle="Calling around for availability or a quote takes time. At Found, we bring the suppliers to you."
          title="Don’t waste your day on the phone"
          to={PublicRoutePath.QUOTE_PROCESS}
        />
        <ContentRow
          buttonText="I’m interested"
          imgSrc={vp3Image}
          subtitle="Our app and website lets you book, modify and cancel orders, 24 hours a day."
          title="All of your bookings in one place"
          to={PublicRoutePath.QUOTE_PROCESS}
        />
      </Container>
      <Box id="how-it-works" className={classes.howItWorks}>
        <Container className={classes.container}>
          <Typography className={classes.howItWorksTitle} variant="h2">
            How it works
          </Typography>
          <Box className={classes.howItWorksSteps}>
            <HowItWorksBox
              imgSrc={step1Icon}
              subtitle="Choose your concrete mix and let us know where you need it delivered."
              title="Pick what you need"
            />
            <HowItWorksBox
              imgSrc={step2Icon}
              subtitle="See real time availability to pick the time and date for your concrete delivery."
              title="Lock in your time"
              extraLinkHref="/check-availability"
              extraLinkText="Check availability"
            />
            <HowItWorksBox
              imgSrc={step3Icon}
              subtitle="We’ll instantly match your order to a supplier with the available time."
              title="We’ll book the supplier"
            />
            <HowItWorksBox
              imgSrc={step4Icon}
              subtitle="You’re all done! Your job is booked in and your concrete is scheduled to be delivered onsite."
              title="Get it delivered"
            />
          </Box>
          <div className={classes.buttonBox}>
            <Link href={PublicRoutePath.QUOTE_PROCESS}>
              <ProceedButton component="a" size="large">
                Get a quote
              </ProceedButton>
            </Link>
          </div>
        </Container>
      </Box>
      <Reviews />
      <Container id="about" className={classes.about}>
        <Typography variant="h2">About Found Concrete</Typography>
        <Typography variant="h3">
          It started with one question: &quot;Is there an easier way to order concrete?&quot;
        </Typography>
        <Typography variant="h4" component="p">
          We began by talking with builders and suppliers to understand their needs. Together, we made a way for
          concrete to be ordered in 2 minutes, anytime of the day.
        </Typography>
        <Typography variant="h4" component="p">
          Since launching in 2019, Found is now a rapidly growing team of 20 passionate industry and digital experts,
          partnering with concrete suppliers across Sydney, the NSW Southern Highlands, Melbourne and Geelong.
        </Typography>
        <Typography variant="h4" component="p">
          We’re proud to be a part of changing the industry and delivering concrete near you.
        </Typography>
        <Box className={classes.aboutButton} mt={7}>
          <Link href={PublicRoutePath.QUOTE_PROCESS}>
            <ProceedButton component="a" size="large">
              Get a quote
            </ProceedButton>
          </Link>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
