import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useWrapper = makeStyles()(({ spacing }: Theme) => ({
  root: {
    maxWidth: 392,
    height: 94,
    margin: spacing(2, 0),
    position: 'relative',
    '& > iframe': {
      border: 'none',
    },
  },
}));
